<template>
  <div style="position: relative">
    <el-select
      v-model="selected"
      placeholder="Select"
      :multiple="allowMultiselect"
      :collapse-tags="allowMultiselect"
      :popper-append-to-body="false"
      filterable
      style="width: 100%; margin: 10px 0"
      ref="dropdownQ"
      @change="onChange"
      popper-class="keyreply-dropdown"
    >
      <el-option
        v-for="(item, index) in selections.data"
        :key="`selections-${index}`"
        :label="item.key"
        :value="item.value"
        :style="{
          fontFamily: $store.getters.settings.font,
        }"
      >
      </el-option>
    </el-select>
    <div style="display: flex; flex-direction: column">
      <el-button
        plain
        size="small"
        style="width: 100%; margin: 0 0 10px 0; white-space: normal"
        :style="{
          fontFamily: $store.getters.settings.font,
        }"
        :type="buttonOutline"
        @click="submitButtonClicked"
      >
        {{ selections.label }}
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash-es";

export default {
  props: ["selections", "buttonOutline"],
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    allowMultiselect() {
      return this.selections.options?.multiselect || false;
    },
    saveAsBoolean() {
      return this.selections.options?.saveAsBoolean || false;
    },
    isSelectedValueMultiselect() {
      return Array.isArray(this.selected) && this.selected.length;
    },
    selectedValue() {
      return this.isSelectedValueMultiselect
        ? this.mapKeepSorts(",", (o) => o.value)
        : this.selected;
    },
    selectedLabel() {
      return this.isSelectedValueMultiselect
        ? this.mapKeepSorts(", ", (o) => o.key)
        : _.find(this.selections.data, { value: this.selected })?.key || this.selected;
    },
  },
  methods: {
    submitButtonClicked() {
      if (this.selectedValue) {
        let payload = {};
        if (this.saveAsBoolean) {
          const all = this.selections.data.map((o) => o.value);
          const selecteds = this.selectedValue.split(",");
          const unselecteds = _.xor(all, selecteds);
          const dataToSet = selecteds.reduce(
            (acc, curr) => ({ ...acc, [`${this.selections.key}_${curr}`]: true }),
            {
              [`${this.selections.key}_label`]: this.selectedLabel,
            }
          );
          const dataToUnset = unselecteds.reduce(
            (acc, curr) => ({ ...acc, [`${this.selections.key}_${curr}`]: false }),
            {}
          );
          payload = {
            data: { ...dataToSet, ...dataToUnset },
          };
        } else {
          payload = {
            data: {
              [this.selections.key]: this.selectedValue,
              [`${this.selections.key}_label`]: this.selectedLabel,
            },
          };
        }
        _.set(payload, "next", this.selections.next);
        this.$emit("sendPostback", {
          text: this.selectedLabel,
          event: "set",
          data: payload,
        });
      }
    },
    mapKeepSorts(delimiter, cb) {
      return _.filter(this.selections.data, (o) => this.selected.includes(o.value))
        .map(cb)
        .join(delimiter);
    },
    onChange() {
      if (this.isSelectedValueMultiselect) {
        this.$nextTick(() => {
          const firstSelectedValue = this.selected[0];
          const firstSelectedLabel = _.find(this.selections.data, {
            value: firstSelectedValue,
          })?.key;
          this.$refs.dropdownQ.$el.querySelector(
            ".el-select__tags-text"
          ).innerHTML = `${firstSelectedLabel.substr(0, 15)}...`;
        });
      }
    },
  },
};
</script>
<style scoped>
.el-select-dropdown__item {
  max-width: 350px;
  white-space: normal;
  overflow: unset;
  height: auto;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

::v-deep .el-select {
  position: relative !important;
}

::v-deep .el-scrollbar__view {
  padding: 10px;
}

::v-deep .el-select-dropdown__item {
  max-width: 100%;
}
</style>

<style>
.keyreply-dropdown {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
}
</style>
