<template>
  <el-row type="flex" style="margin: 0 16px 16px 16px; flex-flow: row wrap">
    <el-button
      size="mini"
      target="_blank"
      :style="{
        fontFamily: fontSettings,
      }"
      :type="buttonOutline"
      :class="{ 'blue-button': buttonOutline === 'primary' }"
      @click="openSingpssAuthUrl"
    >
      Open QR Code
    </el-button>
  </el-row>
</template>

<script>
export default {
  name: "MessageSingPass",
  props: {
    buttonOutline: {
      type: String,
    },
  },
  computed: {
    fontSettings() {
      return this.$store.getters.settings.font;
    },
  },
  methods: {
    openSingpssAuthUrl() {
      const baseUrl = this.$store.state.settings.singpassAuthURL;
      const senderId = this.$store.state.uid;
      const recipient = location.hostname;
      const appSource = this.$store.state.settings.source;
      const singpassState = `${senderId}_${recipient}_${appSource}`;
      const singpassNonce = Date.now();
      const singpassClientId = this.$store.state.settings.singpassClientId;
      const singpassRedirectUri = this.$store.state.settings.singpassRedirectURI;

      const authURL = `${baseUrl}?scope=openid&response_type=code&state=${singpassState}&nonce=${singpassNonce}&client_id=${singpassClientId}&redirect_uri=${singpassRedirectUri}`;

      window.open(authURL);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  margin: 0 5px 5px 0;
  // flex: 1 1 48%;
  border-radius: 25px;
  &.active {
    background: transparent;
  }
}
</style>

<style lang="scss" scoped>
.ui-beta {
  .el-button {
    margin: 0 5px 5px 0;
    border-radius: 8px;
    white-space: normal;
    text-align: left;

    &.blue-button {
      color: #4e6cce;
      background: #dce2f5;
      border-color: #4e6cce;

      &:hover {
        background: #4e6cce;
        color: #fff;
      }
    }

    &.active {
      background: transparent;
    }
  }
}
</style>
