<template>
  <div
    v-if="text"
    class="bubble-wrapper"
    :style="{
      justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
    }"
  >
    <div
      class="message-wrapper"
      :style="bubbleStyle"
      :class="{
        'message-bubble-left': !isUserMessage,
        'message-bubble-right': isUserMessage,
      }"
    >
      <p style="margin-bottom: 0 !important; word-break: break-word" v-html="markedText"></p>
      <template v-if="hasSelection">
        <DropdownSelection
          :selections="message.data.selections"
          :button-outline="buttonOutline"
          :should-disable-if-in-livechat="shouldDisableIfInLivechat"
          @sendPostback="$emit('sendPostback', $event)"
        />
      </template>
      <template v-if="hasButton">
        <br />
        <MessageButtons
          :message="message"
          :button-outline="buttonOutline"
          :should-disable-if-in-livechat="shouldDisableIfInLivechat"
          @sendPostback="$emit('sendPostback', $event)"
        />
      </template>
      <template v-if="hasLearningFeedback">
        <MessageFeedbacks :message="message" @sendPostback="$emit('sendPostback', $event)" />
      </template>
      <div style="text-align: right">
        <i
          style="font-size: 9px !important"
          v-html="timestamp(message)"
          v-if="!isUiBetaEnabled"
        ></i>
        <span
          v-else
          :style="`font-size: 10px !important; color:${
            isUserMessage ? this.settings.userTextColor : this.settings.agentTextColor
          } `"
          v-html="timestamp(message)"
        >
        </span>
      </div>
    </div>
    <ResendMessageButton
      :isVisible="isUserMessage && !isMessageSent"
      :resendMessage="resendMessage"
      :isDisabled="isResendButtonDisabled"
      :errorMessage="message.errorMessage"
    />
    <div class="btn-video-expand" @click="$emit('handleExpandVideo', message)" v-if="isVideo">
      <i class="el-icon-video-play"></i>
    </div>
  </div>
</template>

<script>
import _ from "lodash-es";
import MessageButtons from "./Buttons";
import MessageFeedbacks from "./Feedbacks";
import ResendMessageButton from "./ResendMessageButton";
import DropdownSelection from "./DropdownSelection";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  props: ["message", "buttonOutline", "isUserMessage", "agents", "shouldDisableIfInLivechat"],
  data() {
    return {
      isResendButtonDisabled: false,
      isMessageSent: true,
    };
  },
  components: {
    MessageButtons,
    MessageFeedbacks,
    ResendMessageButton,
    DropdownSelection,
  },
  methods: {
    /**
     * @description Get timestamp
     * @param message
     * @return {string}
     */
    timestamp(message) {
      const source = this.isUserMessage ? "You" : this.replyName;
      return `${source} at ${dayjs(message.timestamp).format("D MMM, hh:mma")}`;
    },
    resendMessage: _.debounce(
      function () {
        this.$set(this, "isResendButtonDisabled", true);
        this.$store.dispatch("RESEND_MESSAGE", {
          id: this.message.id,
          text: this.text,
          timestamp: this.message.timestamp,
        });
      },
      200,
      { leading: true }
    ),
    updateMessageStatus(status) {
      this.$set(this, "isMessageSent", status || status === undefined);
      this.$set(this, "isResendButtonDisabled", false);
    },
    /**
     * @description Check if string only contains whitespace
     * @param string
     * @return {boolean}
     */
    isOnlyWhiteSpace(str) {
      return !str.replace(/\s/g, "").length;
    },
  },
  computed: {
    ...mapGetters(["isUiBetaEnabled"]),
    /**
     * @description Get reply description string `AGENT from ORG`
     * @return {string}
     */
    replyName() {
      const botName = this.$store.state.settings.name || "";
      const botNameValid = botName && !this.isOnlyWhiteSpace(botName);
      const organizationName = !botNameValid
        ? ""
        : this.$store.state.settings.organizationName || "";

      const forceShowAgentName =
        this.agents.length === 1 && this.$store.state.settings.showAgentName;
      const hasMoreThanOneAgentInChat = this.agents.length > 1;
      const fromBot = this.message.type === "reply";
      const isValidName =
        !this.isOnlyWhiteSpace(botName) && !this.isOnlyWhiteSpace(organizationName);

      const prefix = (valid, sender) => (valid ? `${sender} from ` : sender);

      const agentName = hasMoreThanOneAgentInChat ? `<strong>${this.sender}</strong>` : this.sender;
      const botReply = organizationName ? prefix(isValidName, botName) : botName;
      const agentOrBotName = this.sender ? prefix(isValidName, agentName) : botReply;
      const showAgent = fromBot || forceShowAgentName || hasMoreThanOneAgentInChat;
      const replyName = `${showAgent ? agentOrBotName : ""}${organizationName}`;
      return replyName;
    },
    /**
     * @description Get message sender name
     * @return {string}
     */
    sender() {
      const senderName = _.get(this.message, "data.meta.name", null);
      return this.isUserMessage ? null : senderName;
    },

    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },

    /**
     * @description Styling for left and right bubble
     * @return { fontFamily: string; color: string; background: string; borderColor: string }
     */
    bubbleStyle() {
      const background = _.isEmpty(this.settings.background)
        ? "white"
        : `url('${this.settings.background}')`;
      const left = {
        "--left-bubble-color": this.settings.agentBubbleColor,
        "--background": background,
        fontFamily: this.settings.font,
        color: `${this.settings.agentTextColor} !important`,
        background: this.settings.agentBubbleColor,
        borderColor: this.settings.agentBubbleColor + " transparent transparent transparent",
      };

      const right = {
        fontFamily: this.settings.font,
        "--right-bubble-color": this.settings.userBubbleColor,
        "--background": background,
        color: `${this.settings.userTextColor} !important`,
        background: this.settings.userBubbleColor,
        borderColor: this.settings.userBubbleColor + " transparent transparent transparent",
      };
      return !this.isUserMessage ? left : right;
    },

    /**
     * @description Message content text getter
     * @return {string}
     */
    text() {
      const text = _.get(this.message, "data.content[0].text", null);
      const postbackText = _.get(this.message, "data.content[0].postbackText", null);
      const captionText = _.get(this.message, "data.content[0].caption", null);
      const trendingText = this.message.trendingText;
      return trendingText ? trendingText : text || postbackText || captionText;
    },

    /**
     * @description Sanitize html content
     * @return {string}
     */
    markedText() {
      return this.$options.filters.markHTML(this.text, this.userTextColor);
    },

    /**
     * @description checking if message has feedback replies
     * @return {string}
     */
    hasLearningFeedback() {
      return _.get(this.message, "data.learningQuickReplies.length", false);
    },

    /**
     * @description Message has any button
     * @return {boolean}
     */
    hasButton() {
      const buttons = _.get(this.message, "data.content[0].buttons", []);
      return !_.isEmpty(buttons);
    },

    /**
     * @description Message has dropdown selector
     * @return {boolean}
     */
    hasSelection() {
      const selections = _.get(this.message, "data.selections.data", []);
      return !_.isEmpty(selections);
    },
    isVideo() {
      return this.message?.data?.content[0]?.options?.mode === "video";
    },
  },
  watch: {
    message: function (newVal, oldVal) {
      this.updateMessageStatus(newVal.sent);
    },
  },
};
</script>

<style lang="scss">
.message-wrapper {
  display: inline-block;
  margin: 0 16px 10px 16px;
  *:not(button) {
    font-size: 16px !important;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px !important;
    line-height: 1.2;
    padding-bottom: 0;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  ul,
  ol {
    padding: revert;
  }

  li {
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 2px;
  }

  a {
    text-decoration: underline !important;
  }

  a,
  a:visited,
  p,
  li {
    color: inherit;
  }

  img {
    border-radius: 15px;
  }
}

.message-bubble-status {
  font-size: 16px;
  position: absolute;
  right: 2px;
  bottom: 3px;
  z-index: 10;
  font-weight: bold;
}

.message-bubble-left {
  position: relative;
  padding: 15px;
  background: #0b93f6;
  border-radius: 15px;
  color: white;
  text-align: left;
  max-width: 70%;

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 20px;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
    border-left: 20px solid var(--left-bubble-color);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 20px;
    background: var(--background);
    background-repeat: no-repeat;
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
  }
}

.message-bubble-right {
  position: relative;
  padding: 15px;
  color: black;
  background: #e5e5ea;
  max-width: 70%;
  border-radius: 15px;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -2px;
    right: -7px;
    height: 20px;
    border-bottom-left-radius: 16px 14px;
    border-right: 20px solid var(--right-bubble-color);
    -webkit-transform: translate(0, -2px);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    right: -56px;
    width: 26px;
    height: 20px;
    background: var(--background);
    background-repeat: no-repeat;
    border-bottom-left-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
  }
}

.bubble-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
</style>

<style lang="scss">
.ui-beta {
  .message-wrapper {
    li {
      margin-left: 0;
    }
  }

  .message-bubble-status {
    right: 0;
  }

  .message-bubble-left {
    font-style: "Avenir Next";
    padding: 16px;
    background: #eee;
    color: #000;
    max-width: 70%;
    min-width: 45%;

    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }

  .message-bubble-right {
    font-style: "Avenir Next";
    padding: 16px;
    background: #4e6cce;

    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
}

[dir="ltr"] .ui-beta .message-bubble-left {
  border-radius: 16px 16px 16px 0;
}

[dir="rtl"] .ui-beta .message-bubble-left {
  border-radius: 16px 16px 0 16px;
}

[dir="ltr"] .ui-beta .message-bubble-right {
  border-radius: 16px 16px 0 16px;
}

[dir="rtl"] .ui-beta .message-bubble-right {
  border-radius: 16px 16px 16px 0;
}
</style>

<style lang="scss">
[dir="ltr"] .message-wrapper {
  li {
    margin-left: 2px;
  }
}

[dir="rtl"] .message-wrapper {
  li {
    margin-right: 2px;
  }
}

[dir="ltr"] .message-bubble-status {
  right: 2px;
}

[dir="rtl"] .message-bubble-status {
  left: 2px;
}

[dir="ltr"] .message-bubble-left {
  text-align: left;
}

[dir="rtl"] .message-bubble-left {
  text-align: right;
}

[dir="ltr"] .message-bubble-right {
  text-align: left;
}

[dir="rtl"] .message-bubble-right {
  text-align: right;
}
.btn-video-expand {
  margin-left: -10px;
  padding: 5px;
  background: #4e6cce;
  clip-path: circle();
  cursor: pointer;
  i {
    font-size: 30px;
    color: #fff;
  }
}
.btn-video-expand {
  margin-left: -10px;
  padding: 5px;
  background: #4e6cce;
  clip-path: circle();
  cursor: pointer;
  i {
    font-size: 30px;
    color: #fff;
  }
}
.btn-video-expand {
  margin-left: -10px;
  padding: 5px;
  background: #4e6cce;
  clip-path: circle();
  cursor: pointer;
  i {
    font-size: 30px;
    color: #fff;
  }
}
</style>
